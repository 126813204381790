.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.animate {
  animation: fade-in 2s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.main-text {
  font-size: 36px;
  color: var(--color-primary)
}
