.profile-input {
  border-radius: 23.5px;
  border: 1px solid #E0E0E0;
  background: #FFF;
}

.input-label {
  color: #244CAA;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}
