.header-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #FFF;
}

.menu-container {
  background-color: #FFF;
  color: var(--color-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  width: 500px;
  display: flex;
  justify-content: center;
}

.header-logo {
  width: 50px;
  height: 50px;
}

.logout-button {
  color: var(--color-primary);
  font-weight: 500;
  font-size: 18px;
}

.demo-logo {
  display: flex;
  align-items: center;
}

.content-wrapper {
  min-height: calc(100vh - 64px);
}

.loading-view {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #244CAA80;
  opacity: 1;
  z-index: 999;
}

@media screen and (max-width: 480px) {
  .menu-container {
    width: 200px;
  }
}
