.sub-title {
    color: #000;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-top: 0px;
}

.sub-description {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.setting-switch-text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    margin-left: 12px;
}

.swtich-container {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.setting-wrapper {
    margin-top: 24px;
}

.setting-button {
    padding: 0px;
    font-size: 16px;
    font-weight: 600;
}

.separate-line {
    margin-top: 24px;
    max-width: 500px;
    height: 1px;
    background-color: #01010130;
}

@media screen and (max-width: 480px) {
    .separate-line {
        max-width: 100%;
    }
}