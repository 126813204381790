.phone-container {
  width: 100%;
  max-width: 500px;
  height: 80vh;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: auto;
  margin-top: 20vh;
}

.sign-title {
  color: var(--color-primary);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sign-sub-title {
  color: #050505;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 20px;
  line-height: normal;
}

.PhoneInput {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.PhoneInputInput {
  height: 30px;
  border-radius: 25px;
  border: 1px solid var(--gray-5, #E0E0E0);
  background: #FFF;
  padding-left: 16px;
  margin-left: 10px;
}

.login-button {
  width: 100%;
  border-radius: 100px !important;
  margin-top: 20px;
}

@media screen and (max-width: 500px) {
  .phone-container {
    width: 100%;
    max-width: calc(100vw - 26px);
  }
}
