.note-card-content {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  --max-lines: 3;
  margin: 0px;
  margin-top: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}

.note-card-title {
  color: #244CAA;
  font-family: sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  --max-lines: 1;
  margin: 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}

.note-card-container {
  cursor: pointer;
  margin-bottom: 12px;
  width: 100%;
  max-width: 401px;
  max-height: 205px;
  min-height: 205px;
}

.search-input {
  border-radius: 50px;
  height: 50px;
  margin-bottom: 24px;
  border-color: #E0E0E0;
  background-color: #FFF;
  font-size: 18px;
  padding-left: 48px;
}

.search-icon {
  width: 28px;
  height: 28px;
  position: absolute;
  left: 12px;
  top: 12px;
}

.note-tag-button {
  line-height: 16.5px;
  border-radius: 40px;
  margin-right: 10px;
  height: 38px;
  font-size: 16px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  cursor: pointer;
  white-space: nowrap;
}

.note-tag-container {
  display: flex;
  overflow-x: auto;
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
}

.new-note-tag-container {
  display: flex;
  overflow-x: auto;
  width: 100%;
  height: 70px;
  justify-content: flex-start;
  align-items: center;
}

.clear-button {
  margin-left: 12px;
  cursor: pointer;
  font-size: 24px;
  margin-top: 6px;
  color: #01010150;
}

.more-button {
  width: 24px;
  height: 24px;
}

.note-card-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.note-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  margin-top: 12px;
}

.home-top-search-container {
  max-width: 577px;
  width: 100%;
  margin: auto;
}

.home-top-tag-container {
  max-width: 100%;
  width: 100%;
  margin: auto;
}

.note-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}



.home-top-tag-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #1677ff50;
  border-radius: 10px;
  background-color: #ffffff;
}

.home-top-tag-container::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #F5F5F5;
}

.home-top-tag-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #1677ff50;
  background-color: #1677ff;
}

.new-note-modal .ant-modal-content {
  border-top: 8px solid #f7e353;
}

.new-note-modal .ant-modal-content .ant-card {
  border: none;
}
