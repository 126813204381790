.tag-color-dot {
  width: 12px;
  height: 12px;
  border-radius: 100px;
}

.seperate-line {
  max-width: 450px;
  height: 1px;
  background-color: #01010150;
}

.tag-container {
  max-width: 480px;
  width: 100%;
}

.add-button {
  width: 46px;
  height: 41px;
}

.top-container {
  max-width: 480px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
