.tag-button {
  line-height: 16.5px;
  border-radius: 40px;
  margin-right: 10px;
  height: 38px;
  font-size: 16px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 20px;
}

.note-tag-container {
  margin-top: 12px;
}

.back-icon {
  width: 24px;
  height: 24px;
}

.wave-image {
  width: 100%;
  height: 50px;
}

.note-detail-container .ant-card-body {
  padding: 0px;
}

.note-detail-container {
  margin-top: 24px;
}

.detail-container {
  padding: 24px;
}

.note-title {
  color: #244CAA;
  font-family: sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 40px;
  margin-bottom: 12px;
}

.right-icon {
  width: 24px;
  height: 24px;
}

.note-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.current-date-text {
  color: #01010190;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.update-button {
  float: right;
  margin-top: 12px;
  background-color: #244CAA;
  border-radius: 100px;
  height: 40px;
  width: 130px;
}

.pop-tag-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.new-tag-input {
  border-radius: 100px;
  min-width: 120px;
  max-width: 120px;
}

.link-previewer-wrapper {
  margin-top: 16px;
}

.save-note-btn {
  border-radius: 100px;
  font-size: 14px;
  line-height: 1;
  width: 130px;
  height: 40px;
  background-color: #244CAA;
}
